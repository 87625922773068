import Http from 'utils/Http';

const apiEndPoints = {
  getOverview: 'sfa_online.v1.digital_scoring.get_overview',
  getTabsRoundsList: 'sfa_online.v1.digital_scoring.get_tabs_rounds',
  getMedalsList: 'sfa_online.v1.digital_scoring.get_medals',
  getParticipantsList: 'sfa_online.v1.digital_scoring.get_participants',
  getKnockout: 'sfa_online.v1.digital_scoring.get_knockout_card_data',
};

export async function getOverview(params) {
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.getOverview}?sport_event=${params.sport_event}`,
    });
    return res.message.overview;
  } catch (error) {
    console.log(error);
  }
}

export async function getTabsRoundsList(params) {
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.getTabsRoundsList}?sport_event=${params.sport_event}`,
    });
    return res.message;
  } catch (error) {
    console.log(error);
  }
}

export async function getMedalsList(params) {
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.getMedalsList}?sport_event=${params.sport_event}`,
    });
    return res.message;
  } catch (error) {
    console.log(error);
  }
}

export async function getParticipantsList(params) {
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.getParticipantsList}?sport_event=${params?.sport_event}&tab_id=${params?.tab_id}&group_id=${params?.group_id}`,
    });
    return res.message;
  } catch (error) {
    console.log(error);
  }
}

export async function getKnockout(params) {
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.getKnockout}?sport_event=${params.sport_event}`,
    });
    return res.message;
  } catch (e) {
    console.log(e);
  }
}
