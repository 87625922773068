import React from 'react';
import KnockoutComponent from './App';
import { useKnockout } from '../queries/digitalScoringHooks';
import { useParams, useRouteMatch } from 'react-router-dom';
import EventName from '../Component/EventName';

export default function Knockout() {
  const base = useRouteMatch('/:user/score/knockout/:sportEvent');
  const data = useKnockout({
    sport_event: useParams().sportEvent,
  });
  console.log(data.data);
  return (
    <>
      <EventName sport_event={base.params.sportEvent} />
      {data.data && data?.data?.columns?.length ? (
        <KnockoutComponent data={data.data} />
      ) : data.isFetched ? (
        <div className="my-8 p-5 bg-gradient-to-br from-blue-75 to-purple-75 rounded-lg">
          <div
            className="text-center p-8 text-white font-bold rounded-lg mt-4"
            style={{
              background: `url(/assets/sfa_online/node_modules/knockout-component/src/direflow-components/knockout-component/components/images/pattern.png) no-repeat -5% , conic-gradient(from 51.11deg at 50% 58.75%, #8E2DE2 0deg, #4A00E0 360deg)`,
            }}
          >
            There are no Knockouts created for this Sport Event yet
          </div>
        </div>
      ) : null}
    </>
  );
}
