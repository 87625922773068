import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { faMars, faVenus } from '@fortawesome/free-solid-svg-icons';
// import downloadIcon from '../../assets/img/download_icon.png';
import { useOverview } from '../queries/digitalScoringHooks';
import Loader from 'components/Loader';
import { getStartAndEndDate } from '../../constants/DateFunctions.js';

// eslint-disable-next-line
export default function EventName({ sport_event }) {
  const { data, isLoading, error } = useOverview({
    sport_event: sport_event,
  });
  // console.log(data);
  // console.log(error);
  if (isLoading) return <Loader />;
  if (error === null && data === undefined) return '';
  return (
    <>
      <div className="bg-heat-event-name-mobile lg:bg-heat-event-name bg-cover pl-4 md:pl-8 pr-2 md:pr-4 rounded-none lg:rounded-2xl overflow-hidden lg:mt-8 flex justify-between">
        <div className="py-4 md:py-12">
          <div className="flex gap-2.5 items-center">
            <h1 className="text-lg md:text-4xl text-white font-bold md:italic">
              {data?.sport} {data?.category}
            </h1>
            {data?.sub_category ? (
              <p className="bg-green-450 px-5 py-1 rounded uppercase text-white font-semibold md:italic font-poppins text-xxs md:text-xs">
                {data.sub_category}
              </p>
            ) : (
              ''
            )}
          </div>
          <div className="flex flex-wrap gap-4 mt-3 items-center">
            <div className="flex gap-2 items-center">
              <svg
                width="13"
                height="13"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.3877 1.73203H13.6357V0.228027H12.1317V1.73203H4.61168V0.228027H3.10768V1.73203H2.35568C1.52848 1.73203 0.851685 2.40883 0.851685 3.23603V15.268C0.851685 16.0952 1.52848 16.772 2.35568 16.772H14.3877C15.2149 16.772 15.8917 16.0952 15.8917 15.268V3.23603C15.8917 2.40883 15.2149 1.73203 14.3877 1.73203ZM14.3877 15.268H2.35568V6.99603H14.3877V15.268ZM14.3877 5.49203H2.35568V3.23603H14.3877V5.49203Z"
                  fill="white"
                />
              </svg>
              <p className="text-white text-xxs md:text-sm italic font-semibold opacity-60">
                {data?.age_group}
              </p>
            </div>
            <div className="flex gap-2 items-center">
              <FontAwesomeIcon
                icon={data?.gender === 'Male' ? faMars : faVenus}
                className="text-white text-xxs md:text-xs h-3 w-auto"
              />
              <p className="text-white text-xxs md:text-sm italic font-semibold opacity-60">
                {data?.gender}
              </p>
            </div>
            {data?.start_date && data?.end_date ? (
              <div className="flex gap-2 items-center h-3 w-auto">
                <FontAwesomeIcon icon={faCalendar} className="text-white text-xxs md:text-xs" />
                <p className="text-white text-xxs md:text-sm italic font-semibold opacity-60">
                  {getStartAndEndDate(data?.start_date, data?.end_date)}
                </p>
              </div>
            ) : null}
            <div className={`flex gap-2 items-center ${data?.venues.length === 0 ? 'hidden' : ''}`}>
              <svg
                width="16"
                height="16"
                viewBox="0 0 19 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_37_4596)">
                  <path
                    d="M9.07186 2.48779C6.16162 2.48779 3.80786 4.84155 3.80786 7.75179C3.80786 11.6998 9.07186 17.5278 9.07186 17.5278C9.07186 17.5278 14.3359 11.6998 14.3359 7.75179C14.3359 4.84155 11.9821 2.48779 9.07186 2.48779ZM5.31186 7.75179C5.31186 5.67627 6.99634 3.99179 9.07186 3.99179C11.1474 3.99179 12.8319 5.67627 12.8319 7.75179C12.8319 9.91755 10.6661 13.1587 9.07186 15.1816C7.5077 13.1737 5.31186 9.89499 5.31186 7.75179Z"
                    fill="white"
                  />
                  <path
                    d="M9.07189 9.63183C10.1102 9.63183 10.9519 8.79012 10.9519 7.75183C10.9519 6.71353 10.1102 5.87183 9.07189 5.87183C8.0336 5.87183 7.19189 6.71353 7.19189 7.75183C7.19189 8.79012 8.0336 9.63183 9.07189 9.63183Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_37_4596">
                    <rect
                      width="18.048"
                      height="18.048"
                      fill="white"
                      transform="translate(0.0479736 0.983887)"
                    />
                  </clipPath>
                </defs>
              </svg>
              <p className="text-white text-xxs md:text-sm italic font-semibold opacity-60 -ml-1">
                {data?.venues[0]}
              </p>
            </div>
          </div>
        </div>
        <div className="pt-4">
          {/* <img src={downloadIcon} alt="downloadIcon" className="w-6 h-6 cursor-pointer" /> */}
        </div>
      </div>
    </>
  );
}
