import React, { useEffect, useMemo, useState } from 'react';
import EventName from '../Component/EventName';
import {
  useMedalsList,
  useParticipantsList,
  useTabsRoundsList,
} from '../queries/digitalScoringHooks';
import TabsRounds from '../Component/TabsRounds';
import TabsRoundsMobile from '../Component/TabsRoundsMobile';
import Groups from '../Component/Groups';
import MedalsFinals from '../Component/MedalsFinals';
import Table from '../Component/Table';
import { useParams } from 'react-router-dom';
import DetailItemLoader from 'components/DetailItemLoader';
// import { getTime } from 'constants/DateFunctions';
// import MobileTable from '../Component/MobileTable';
import MobileCard from '../Component/MobileCard';
import MobileTable from '../Component/MobileTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

export default function Heat() {
  const params = useParams();
  const [sortedData, setSortedData] = useState([]);

  /*
  const overviewData = useOverview({
    sport_event: params.sportEvent,
  });
*/

  const tabRoundsListQueryData = useTabsRoundsList({
    sport_event: params.sportEvent,
  });

  const medalsListQueryData = useMedalsList({
    sport_event: params.sportEvent,
  });

  const [selected, setSelected] = useState('');
  const [selected2, setSelected2] = useState('');
  const [showMedals, setShowMedals] = useState(false);

  useEffect(() => {
    tabRoundsListQueryData.data
      ? setSelected(tabRoundsListQueryData?.data[0]?.tab_id)
      : setSelected('');
  }, []);
  useEffect(() => {
    tabRoundsListQueryData.data
      ? setSelected2(tabRoundsListQueryData?.data[0]?.groups[0]?.group_id)
      : setSelected2('');
  }, [tabRoundsListQueryData && tabRoundsListQueryData.status === 'success']);

  const participantsListQueryData = useParticipantsList({
    sport_event: params.sportEvent,
    tab_id: selected,
    group_id: showMedals ? '' : selected2,
  });

  useEffect(() => {
    participantsListQueryData.refetch();
  }, [selected, selected2, params.sportEvent]);
  useEffect(() => {
    if (participantsListQueryData.isFetched)
      if (!selected || (selected && selected?.split('-')[0].toLowerCase() !== 'final'))
        setSortedData(() => [...participantsListQueryData.data].sort((a, b) => a.lane - b.lane));
      else setSortedData(() => [...participantsListQueryData.data]);
  }, [participantsListQueryData?.data]);
  const [Group, setGroup] = useState([]);
  useEffect(() => {
    if (tabRoundsListQueryData.isLoading) return;
    let i;
    for (i = 0; i < tabRoundsListQueryData?.data.length; i++) {
      if (tabRoundsListQueryData?.data[i]?.tab_id === selected) {
        setSelected2(
          tabRoundsListQueryData?.data[i]?.groups[0]?.group_id
            ? tabRoundsListQueryData?.data[i]?.groups[0]?.group_id
            : ''
        );
        break;
      }
    }
  }, [selected]);

  const setTabs = (idx) => {
    setSelected(idx);
  };

  useEffect(() => {
    if (
      tabRoundsListQueryData.data &&
      tabRoundsListQueryData.data.length !== 0 &&
      tabRoundsListQueryData.isLoading === false
    ) {
      document.querySelector('.tabs')?.click();
    }
  }, [tabRoundsListQueryData.isLoading]);

  const setHeat = (idx) => {
    setSelected2(idx);
  };

  const setGroupOnTabs = (group) => {
    setGroup(group);
  };
  const showLane = useMemo(() => {
    if (Array.isArray(participantsListQueryData.data)) {
      return participantsListQueryData?.data?.find(
        (el) => (el.lane !== 0 && el.lane) || el.lane === 0
      );
    }
  }, [participantsListQueryData.data, participantsListQueryData.isFetched]);
  console.log(showLane);
  const columns = showLane
    ? [
        // {
        //   key: 'lane',
        //   label: 'Lane',
        //   component: 'TextColumn',
        // },
        {
          key: 'lane',
          label: 'Lane',
          component: 'TextColumn',
        },
        {
          key: 'sfa_id',
          label: 'SFA ID',
          component: 'TextColumn',
        },
        {
          key: 'athlete_name',
          label: 'Athlete (Student) Name',
          component: 'TextAndImage',
        },
        {
          key: 'institution',
          label: 'Institution',
          component: 'TextAndImage',
        },
        {
          key: 'result',
          label: 'Results',
          component: 'ResultButton',
        },
        {
          key: 'timing',
          label: 'Timing',
          component: 'TextColumn',
        },
      ]
    : [
        // {
        //   key: 'lane',
        //   label: 'Lane',
        //   component: 'TextColumn',
        // },
        {
          key: 'sfa_id',
          label: 'SFA ID',
          component: 'TextColumn',
        },
        {
          key: 'athlete_name',
          label: 'Athlete (Student) Name',
          component: 'TextAndImage',
        },
        {
          key: 'institution',
          label: 'Institution',
          component: 'TextAndImage',
        },
        {
          key: 'result',
          label: 'Results',
          component: 'ResultButton',
        },
        {
          key: 'timing',
          label: 'Timing',
          component: 'TextColumn',
        },
      ];
  return (
    <div>
      <EventName sport_event={params.sportEvent} />

      {tabRoundsListQueryData.data && tabRoundsListQueryData.data.length !== 0 ? (
        <>
          <TabsRounds
            tabRoundsListQueryData={tabRoundsListQueryData}
            setTabs={setTabs}
            selected={selected}
            showMedals={showMedals}
            setShowMedals={setShowMedals}
            setGroupOnTabs={setGroupOnTabs}
          />
          <TabsRoundsMobile
            tabRoundsListQueryData={tabRoundsListQueryData}
            setTabs={setTabs}
            selected={selected}
            showMedals={showMedals}
            setShowMedals={setShowMedals}
            setGroupOnTabs={setGroupOnTabs}
          />
        </>
      ) : (
        <div className="w-full h-28 flex flex-col justify-center text-center bg-white font-medium text-xs md:text-lg">
          Rounds are not created yet for this sport event
        </div>
      )}
      {showMedals ? '' : <Groups Group={Group} setHeat={setHeat} selected2={selected2} />}
      {showMedals ? (
        medalsListQueryData.data.length !== 0 ? (
          <MedalsFinals
            medalsListQueryData={medalsListQueryData}
            showMedals={showMedals}
            setShowMedals={setShowMedals}
            isValid={medalsListQueryData?.data?.error?.length}
            Group={Group}
          />
        ) : null
      ) : (
        ''
      )}
      <div className="flex justify-between items-center p-4 hidden -mb-8">
        <p className="font-bold">Match Details</p>
        <FontAwesomeIcon icon={faSearch} className="text-gray-450" />
      </div>
      {/* mobile table */}
      <div className="flex flex-col gap-4 md:hidden py-4 sm:px-4">
        <DetailItemLoader queryData={participantsListQueryData}>
          {(data) =>
            data && sortedData?.length ? (
              sortedData.map((item, index) => {
                return item.opponent_1 ? (
                  <MobileTable key={index} Data={item} />
                ) : (
                  <MobileCard key={index} Data={item} />
                );
              })
            ) : (
              <div
                className="text-center p-8 text-white font-bold rounded-lg text-sm md:text-md"
                style={{
                  background: `url(/assets/sfa_online/node_modules/knockout-component/src/direflow-components/knockout-component/components/images/pattern.png) no-repeat -5% , conic-gradient(from 51.11deg at 50% 58.75%, #8E2DE2 0deg, #4A00E0 360deg)`,
                }}
              >
                Matches yet to be played / scheduled
              </div>
            )
          }
        </DetailItemLoader>
      </div>
      {/* mobile table */}

      {/* desktop table */}
      <div className="my-8 p-5 bg-gradient-to-br from-blue-75 to-purple-75 rounded-lg hidden md:block">
        <DetailItemLoader queryData={participantsListQueryData}>
          {(data) =>
            data && data?.data?.length ? (
              <Table rows={sortedData} columns={columns} />
            ) : (
              <div
                className="text-center p-8 text-white font-bold rounded-lg text-sm md:text-md"
                style={{
                  background: `url(/assets/sfa_online/node_modules/knockout-component/src/direflow-components/knockout-component/components/images/pattern.png) no-repeat -5% , conic-gradient(from 51.11deg at 50% 58.75%, #8E2DE2 0deg, #4A00E0 360deg)`,
                }}
              >
                Matches yet to be played / scheduled
              </div>
            )
          }
        </DetailItemLoader>
      </div>
      {/* desktop table */}
    </div>
  );
}
