export default function MobileTable({
  Data: {
    match_round: matchRound,
    match_no: matchNo,
    opponent_1: opponent1,
    opponent_2: opponent2,
    date_time: dateTime,
    status,
    match_status: matchStatus,
  },
  isSwissLeague = false,
  checkForDraws,
}) {
  const draw = checkForDraws && (matchStatus === 'DRAW' || status === 'DRAW');
  const actualStatus = status || matchStatus;
  // console.log({ isSwissLeague });
  return (
    <div className="md:hidden mt-5 rounded-lg flex flex-col border border-gray-300 font-roboto bg-white">
      {/* top part */}
      <div
        className={`text-xxs rounded-t-lg flex justify-between px-4 py-1 bg-gray-125 border-b border-gray-250 ${
          isSwissLeague === true ? 'hidden' : ''
        }`}
      >
        <span>{matchRound || (matchNo && 'Match ' + matchNo)}</span>
        <span>
          {opponent1.score
            ? 'Score: ' + opponent1.score
            : actualStatus === 'DIGITAL_SCORE'
            ? 'Yet To Play'
            : actualStatus === 'NO_SHOW'
            ? 'No Show'
            : '-'}
        </span>
      </div>
      {/* middle part */}

      <div className="flex justify-around gap-2 py-2">
        <div className="flex justify-start items-center  pr-0 gap-2">
          <img
            src={opponent1?.image || opponent1?.athlete_name?.image}
            alt="dp"
            className={`bg-blue-950 h-8 w-8 rounded-full flex-shrink-0 ${
              opponent1?.is_won === 1 || opponent1?.won === 1 ? 'border-3 border-green-600' : ''
            } ${opponent1?.is_won === 0 || opponent1?.won === 0 ? 'border-3 border-red-350' : ''}`}
          />
          <div className="flex flex-col gap-2  w-24 justify-between h-full">
            <span className="text-xxs w-16 break-all">
              {opponent1?.name || opponent1?.athlete_name?.name}
            </span>
            <div className="flex flex-col gap-1">
              {isSwissLeague && (
                <span className="text-xxs truncate">
                  {typeof opponent1.institution === 'string'
                    ? opponent1?.institution
                    : typeof opponent1?.institution === 'object'
                    ? opponent1?.institution?.name
                    : null}
                </span>
              )}{' '}
              {actualStatus !== 'DIGITAL_SCORE' && (
                <span
                  className={`text-mini px-1.5 py-0.5 rounded w-min whitespace-nowrap uppercase font-roboto ${
                    !draw && (opponent1?.won === 1 || opponent1.is_won === 1)
                      ? 'bg-green-450 text-white'
                      : 'text-gray-450 bg-gray-150'
                  }`}
                >
                  {!draw &&
                    actualStatus !== 'NO_SHOW' &&
                    (opponent1?.won === 1 || opponent1?.is_won === 1
                      ? 'Won'
                      : opponent1?.won === 0 || opponent1?.is_won === 0
                      ? 'Lost'
                      : opponent1.sfa_id)}
                  {draw ? 'Draw' : ''}
                  {actualStatus === 'NO_SHOW' ? 'NO SHOW' : ''}
                </span>
              )}
              {!isSwissLeague && (
                <span className="text-xxs truncate">
                  {typeof opponent1.institution === 'string'
                    ? opponent1?.institution
                    : typeof opponent1?.institution === 'object'
                    ? opponent1?.institution?.name
                    : null}
                </span>
              )}
              {isSwissLeague ? (
                <>
                  <div className="text-xxs text-gray-650 -mb-1">
                    Current Standing: {opponent1?.current_standing}
                  </div>
                  <div className="text-xxs text-gray-650">
                    Round Point: {opponent1?.round_point}
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </div>

        <div className="text-xxs my-auto">v/s</div>

        <div className="flex justify-start items-center pl-0 gap-2">
          <img
            src={opponent2?.image || opponent2?.athlete_name?.image}
            alt="dp"
            className={`bg-blue-950 h-8 w-8 rounded-full flex-shrink-0 ${
              opponent2?.is_won === 1 || opponent2?.won === 1 ? 'border-3 border-green-600' : ''
            } ${opponent2?.is_won === 0 || opponent2?.won === 0 ? 'border-3 border-red-350' : ''}`}
          />
          <div className="flex flex-col  gap-2 w-24 justify-between h-full">
            <span className="text-xxs w-16 break-all">
              {opponent2?.name || opponent1?.athlete_name?.name}
            </span>{' '}
            <div className="flex flex-col gap-1">
              {isSwissLeague && (
                <span className="text-xxs truncate">
                  {typeof opponent1.institution === 'string'
                    ? opponent1?.institution
                    : typeof opponent1?.institution === 'object'
                    ? opponent1?.institution?.name
                    : null}
                </span>
              )}

              {actualStatus !== 'DIGITAL_SCORE' && (
                <span
                  className={`text-mini px-1.5 py-0.5  rounded w-min whitespace-nowrap uppercase font-roboto ${
                    !draw && (opponent2?.won === 1 || opponent2?.is_won === 1)
                      ? 'bg-green-450 text-white'
                      : 'text-gray-450 bg-gray-150'
                  }`}
                >
                  {!draw &&
                    actualStatus !== 'NO_SHOW' &&
                    (opponent2?.won === 1 || opponent2?.is_won === 1
                      ? 'Won'
                      : opponent2?.won === 0 || opponent2?.is_won === 0
                      ? 'Lost'
                      : opponent2.sfa_id)}{' '}
                  {draw ? 'Draw' : ''}
                  {actualStatus === 'NO_SHOW' ? 'NO SHOW' : ''}
                </span>
              )}

              {!isSwissLeague && (
                <span className="text-xxs truncate">
                  {typeof opponent1.institution === 'string'
                    ? opponent1?.institution
                    : typeof opponent1?.institution === 'object'
                    ? opponent1?.institution?.name
                    : null}
                </span>
              )}
              {isSwissLeague ? (
                <>
                  <div className="text-xxs text-gray-650 -mb-1">
                    Current Standing: {opponent2?.current_standing}
                  </div>
                  <div className="text-xxs text-gray-650">
                    Round Point: {opponent2?.round_point}
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      {/* bottom part */}
      <div
        className={`text-xxs px-4 py-1 rounded-b-lg flex justify-between bg-gray-125 border-t border-gray-250 ${
          isSwissLeague === true ? 'hidden' : ''
        }`}
      >
        <span className="opacity-750">
          {`${new Date(dateTime).toLocaleDateString('en-UK', {
            day: '2-digit',
            month: 'short',
            year: '2-digit',
          })} | ${new Date(dateTime).toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
          })}` || '-'}
        </span>
      </div>
    </div>
  );
}
