import React, { useEffect, useState } from 'react';
import EventName from '../Component/EventName';
import Table from '../Component/Table';
import {
  useMedalsList,
  useParticipantsList,
  useTabsRoundsList,
} from '../queries/digitalScoringHooks';
import { useParams } from 'react-router-dom';
import TabsRounds from '../Component/TabsRounds';
import TabsRoundsMobile from '../Component/TabsRoundsMobile';
import Groups from '../Component/Groups';
import MedalsFinals from '../Component/MedalsFinals';
import DetailItemLoader from 'components/DetailItemLoader';
import MobileTable from 'digital-scoring/Component/MobileTable';
import MobileCard from '../Component/MobileCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

export default function League() {
  const params = useParams();

  /*
  const overviewData = useOverview({
    sport_event: params.sportEvent,
  });
*/

  const tabRoundsListQueryData = useTabsRoundsList({
    sport_event: params.sportEvent,
  });

  const medalsListQueryData = useMedalsList({
    sport_event: params.sportEvent,
  });
  // console.log(medalsListQueryData.data.error.length);

  // console.log(medalsListQueryData);
  const [selected, setSelected] = useState('');
  const [selected2, setSelected2] = useState('');
  const [showMedals, setShowMedals] = useState(false);

  // console.log(tabRoundsListQueryData);

  useEffect(() => {
    tabRoundsListQueryData.data
      ? setSelected(tabRoundsListQueryData?.data[0]?.tab_id)
      : setSelected('');
  }, []);

  useEffect(() => {
    tabRoundsListQueryData.data
      ? setSelected2(tabRoundsListQueryData?.data[0]?.groups[0]?.group_id)
      : setSelected2('');
  }, [tabRoundsListQueryData && tabRoundsListQueryData.status === 'success']);

  const participantsListQueryData = useParticipantsList({
    sport_event: params.sportEvent,
    tab_id: selected,
    group_id: showMedals ? '' : selected2,
  });

  useEffect(() => {
    participantsListQueryData.refetch();
  }, [selected, selected2, params.sportEvent]);

  const [Group, setGroup] = useState([]);
  useEffect(() => {
    if (tabRoundsListQueryData.isLoading) return;
    let i;
    for (i = 0; i < tabRoundsListQueryData?.data.length; i++) {
      if (tabRoundsListQueryData?.data[i]?.tab_id === selected) {
        setSelected2(
          tabRoundsListQueryData?.data[i]?.groups[0]?.group_id
            ? tabRoundsListQueryData?.data[i]?.groups[0]?.group_id
            : ''
        );
        break;
      }
    }
  }, [selected]);

  const setTabs = (idx) => {
    setSelected(idx);
  };

  useEffect(() => {
    if (tabRoundsListQueryData.isLoading === false) {
      document.querySelector('.tabs')?.click();
    }
  }, [tabRoundsListQueryData.isLoading]);

  const setHeat = (idx) => {
    setSelected2(idx);
  };

  const setGroupOnTabs = (group) => {
    setGroup(group);
  };

  const columns = [
    {
      key: 'position',
      label: 'Position',
      component: 'TextColumn',
    },
    // {
    //   key: 'sfa_id',
    //   label: 'SFA ID',
    //   component: 'TextColumn',
    // },
    {
      key: 'athlete_name',
      label:
        (Array.isArray(participantsListQueryData?.data) &&
          participantsListQueryData?.data[0]?.sport_type) ||
        'Team Name',
      component: 'TextAndImage',
    },
    {
      key: 'institution',
      label: 'Institution',
      component: 'TextAndImage',
    },
    {
      key: 'match_count',
      label: 'Matches',
      component: 'TextColumn',
    },
    {
      key: 'won',
      label: 'Won',
      component: 'TextColumn',
    },
    {
      key: 'lost',
      label: 'Lost',
      component: 'TextColumn',
    },
    {
      key: 'draw',
      label: 'Draw',
      component: 'TextColumn',
    },
    {
      key: 'points',
      label: 'Points',
      component: 'TextColumn',
    },
  ];

  const columns2 = [
    {
      key: 'match_no',
      label: 'Match #',
      component: 'TextColumn',
    },
    {
      key: 'date_time',
      label: 'Date and Time',
      component: 'DateTimeColumn',
    },
    {
      key: 'opponent_1',
      label: 'Opponent 1',
      component: 'OpponentColumn',
      checkForDraws: 'opponent_2',
    },
    {
      key: 'opponent_2',
      label: 'Opponent 2',
      component: 'OpponentColumn',
      checkForDraws: 'opponent_1',
    },
    {
      key: 'score',
      label: 'Score',
      component: 'TextColumn',
    },
    // {
    //   key: 'video',
    //   label: 'Video',
    //   component: 'VideoColumn',
    // },
  ];

  return (
    <div>
      <EventName sport_event={params.sportEvent} />
      <TabsRounds
        tabRoundsListQueryData={tabRoundsListQueryData}
        setTabs={setTabs}
        selected={selected}
        showMedals={showMedals}
        setShowMedals={setShowMedals}
        setGroupOnTabs={setGroupOnTabs}
      />
      <TabsRoundsMobile
        tabRoundsListQueryData={tabRoundsListQueryData}
        setTabs={setTabs}
        selected={selected}
        showMedals={showMedals}
        setShowMedals={setShowMedals}
        setGroupOnTabs={setGroupOnTabs}
      />
      {showMedals ? '' : <Groups Group={Group} setHeat={setHeat} selected2={selected2} />}
      {selected === 'knockout' || selected === 'matches' ? (
        medalsListQueryData?.data?.length > 0 ? (
          <MedalsFinals
            medalsListQueryData={medalsListQueryData}
            isValid={medalsListQueryData?.data?.error?.length}
            Group={Group}
          />
        ) : null
      ) : null}

      <div className="flex justify-between items-center p-4 hidden -mb-8">
        <p className="font-bold">Match Details</p>
        <FontAwesomeIcon icon={faSearch} className="text-gray-450" />
      </div>
      {/* <div className="my-8 p-5 bg-gradient-to-br from-blue-75 to-purple-75 rounded-lg md:block hidden"> */}
      <DetailItemLoader queryData={participantsListQueryData}>
        {({ data }) =>
          data && data.length > 0 ? (
            <>
              <div className="my-8 p-5 bg-gradient-to-br from-blue-75 to-purple-75 rounded-lg md:block hidden">
                <Table
                  rows={data}
                  columns={
                    selected === 'knockout' || selected === 'finals' || selected === 'matches'
                      ? columns2
                      : columns
                  }
                />
              </div>
              <div className="md:hidden">
                {data.map((item, index) =>
                  item.opponent_1 ? (
                    <MobileTable key={index} Data={item} checkForDraws />
                  ) : (
                    <MobileCard key={index} Data={item} />
                  )
                )}
              </div>
            </>
          ) : (
            <div
              className="text-center p-8 text-white font-bold rounded-lg"
              style={{
                background: `url(/assets/sfa_online/node_modules/knockout-component/src/direflow-components/knockout-component/components/images/pattern.png) no-repeat -5% , conic-gradient(from 51.11deg at 50% 58.75%, #8E2DE2 0deg, #4A00E0 360deg)`,
              }}
            >
              There are no draws created for this Sport Event yet
            </div>
          )
        }
      </DetailItemLoader>
    </div>
    // </div>
  );
}
