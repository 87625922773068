import React, { useRef } from 'react';
import ListLoader from '../../components/ListLoader';
import { getStartAndEndDateWithTime } from 'constants/DateFunctions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

const variants = {
  purple: {
    bg: 'text-white bg-dull-pattern italic font-bold',
    text: 'text-gray-450 text-xl font-black font-poppins ',
    selectedBG: 'bg-pattern italic font-bold',
    selectedText: 'text-white text-xl font-black ',
    border: ``,
  },
  white: {
    bg: 'bg-white',
    text: 'text-gray text-xl font-bold',
    selectedBG: 'text-gray-450',
    selectedText:
      'bg-clip-text text-transparent bg-gradient-to-br from-blue-450 to-purple-650 text-xl font-bold ',
    border: `border-1`,
  },
};
export default function TabsRounds({
  tabRoundsListQueryData,
  setTabs,
  selected,
  setGroupOnTabs,
  variant = 'white',
  setShowMedals = () => {},
  enableScrollButtons,
}) {
  const scrollRef = useRef(null);
  return (
    <div className="relative justify-between flex hidden md:block">
      {' '}
      {enableScrollButtons ? (
        <button
          onClick={() => {
            scrollRef.current.scrollTo({
              left: scrollRef.current.scrollLeft - 288,
              behavior: 'smooth',
            });
          }}
          className="justify-start my-auto -mr-4 bg-white z-10 px-3 py-2 rounded-full text-purple-650 shadow-shadowLeftRight absolute -left-3 top-5"
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>
      ) : null}
      <div
        className="my-8 flex gap-5 overflow-x-scroll no-scrollbar relative w-full"
        style={{
          scrollSnapType: 'x mandatory',
          scrollBehavior: 'smooth',
        }}
        ref={scrollRef}
      >
        <ListLoader queryData={tabRoundsListQueryData} queryKey="id">
          {({ item }) => {
            return (
              <div
                key={item?.tab_id}
                className={`${
                  enableScrollButtons ? 'w-72 flex-shrink-0' : 'w-full'
                } justify-between flex gap-5`}
                style={{ scrollSnapAlign: 'start' }}
              >
                <button
                  key={item?.tab_id}
                  className={`tabs py-3 w-full bg-white items-center rounded-lg ${
                    variants[variant].border
                  } ${
                    selected === item.tab_id
                      ? `tabs shadow-purpleDrop border-purple-650 ${variants[variant].selectedBG}`
                      : `shadow-none border-gray-250 ${variants[variant].bg}`
                  } ${item?.start_date ? 'min-h-24' : 'h-full'}`}
                  onClick={() => {
                    setTabs(item.tab_id);
                    setGroupOnTabs(item.groups);
                    if (item.medals === true) {
                      setShowMedals(true);
                    } else {
                      setShowMedals(false);
                    }
                  }}
                >
                  <h3
                    className={` ${
                      selected === item.tab_id
                        ? `${variants[variant].selectedText}`
                        : `${variants[variant].text}`
                    }`}
                  >
                    {item?.tab_name}
                  </h3>
                  {item.start_date ? (
                    <p className="mt-1 text-xs text-gray-400">
                      {getStartAndEndDateWithTime(item?.start_date, item?.end_date)}
                    </p>
                  ) : (
                    ''
                  )}
                </button>
              </div>
            );
          }}
        </ListLoader>
      </div>
      {enableScrollButtons ? (
        <button
          onClick={() => {
            scrollRef.current.scrollTo({
              left: scrollRef.current.scrollLeft + 288,
              behavior: 'smooth',
            });
          }}
          className="justify-start my-auto -mr-4 bg-white z-10 px-3 py-2 rounded-full text-purple-650 shadow-shadowLeftRight absolute -right-3 top-5"
        >
          <FontAwesomeIcon icon={faArrowRight} />
        </button>
      ) : null}
    </div>
  );
}
