import React, { useEffect, useState } from 'react';
import EventName from '../Component/EventName';
import Table from '../Component/Table';
import {
  useMedalsList,
  useOverview,
  useParticipantsList,
  useTabsRoundsList,
} from '../queries/digitalScoringHooks';
import TabsRounds from '../Component/TabsRounds';
import TabsRoundsMobile from '../Component/TabsRoundsMobile';
import Groups from '../Component/Groups';
import MedalsFinals from '../Component/MedalsFinals';
import { useParams } from 'react-router-dom';
import DetailItemLoader from 'components/DetailItemLoader';
import MobileTable from '../Component/MobileTable';
import MobileCard from '../Component/MobileCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

export default function QualifyingKnockout() {
  const params = useParams();
  const tabRoundsListQueryData = useTabsRoundsList({
    sport_event: params.sportEvent,
  });

  const medalsListQueryData = useMedalsList({
    sport_event: params.sportEvent,
  });
  // console.log(medalsListQueryData.data.error.length);

  // console.log(medalsListQueryData);
  const [selected, setSelected] = useState('');
  const [selected2, setSelected2] = useState('');

  /*
  const overviewData = useOverview({
    sport_event: params.sportEvent,
  });
*/
  // console.log(tabRoundsListQueryData);
  const { data, isFetched } = useOverview({
    sport_event: params.sportEvent,
  });
  console.log(data);
  useEffect(() => {
    tabRoundsListQueryData.data
      ? setSelected(tabRoundsListQueryData?.data[0]?.tab_id)
      : setSelected('');
  }, []);

  useEffect(() => {
    tabRoundsListQueryData.data
      ? setSelected2(tabRoundsListQueryData?.data[0]?.groups[0]?.group_id)
      : setSelected2('');
  }, [selected]);

  const participantsListQueryData = useParticipantsList({
    sport_event: params.sportEvent,
    tab_id: selected,
    group_id: selected2 || '',
  });

  useEffect(() => {
    participantsListQueryData.refetch();
    // console.log(participantsListQueryData.data);
  }, [selected, selected2, params.sportEvent]);

  const [Group, setGroup] = useState([]);

  const setTabs = (idx) => {
    setSelected(idx);
    let i;
    for (i = 0; i < tabRoundsListQueryData.data.length; i++) {
      if (tabRoundsListQueryData.data[i].tab_id === idx) {
        setSelected2(tabRoundsListQueryData?.data[i]?.groups[0]?.group_id);
        break;
      }
      // setSelected2(tabRoundsListQueryData?.data[0]?.groups[0]?.group_id);
    }
  };

  useEffect(() => {
    if (tabRoundsListQueryData.isFetched) {
      document.querySelector('.tabs')?.click();
    }
  }, [tabRoundsListQueryData.isLoading]);

  const setHeat = (idx) => {
    setSelected2(idx);
  };

  const setGroupOnTabs = (group) => {
    setGroup(group);
  };

  const columns = [
    {
      key: 'position',
      label: 'Position',
      component: 'TextColumn',
    },
    {
      key: 'sfa_id',
      label: 'SFA ID',
      component: 'TextColumn',
    },
    {
      key: 'athlete_name',
      label: isFetched && data?.type?.toLowerCase() === 'team' ? 'Team Name' : 'Athlete Name',
      component: 'TextAndImage',
    },
    {
      key: 'institution',
      label: 'Institution',
      component: 'TextAndImage',
    },
    // {
    //   key: 'match_count',
    //   label: 'Matches',
    //   component: 'TextColumn',
    // },
    // {
    //   key: 'won',
    //   label: 'Won',
    //   component: 'TextColumn',
    // },
    // {
    //   key: 'lost',
    //   label: 'Lost',
    //   component: 'TextColumn',
    // },
    // {
    //   key: 'draw',
    //   label: 'Draw',
    //   component: 'TextColumn',
    // },
    // {
    //   key: 'points',
    //   label: 'Points',
    //   component: 'TextColumn',
    // },
    {
      key: 'score',
      label: 'Score',
      optionalClasses: 'block-flex flex-nowrap items-center p-4',
      component: 'TextColumn',
    },
    {
      key: 'result',
      label: 'Result',
      component: 'ResultButton',
    },
  ];

  const columns2 = [
    {
      key: 'round',
      label: 'Round',
      component: 'TextColumn',
    },
    {
      key: 'date_time',
      label: 'Date and Time',
      component: 'DateTimeColumn',
    },
    {
      key: 'opponent_1',
      label: 'Opponent 1',
      component: 'OpponentColumn',
      priority: true,
      callback: (row, columnKey, priority) => {
        if (row.match_round !== 'Round 1') {
          if (priority !== 0) {
            return 'opponent_2';
          }
        }
      },
    },
    {
      key: 'opponent_2',
      label: 'Opponent 2',
      component: 'OpponentColumn',
      priority: true,
      callback: (row, columnKey, priority) => {
        if (row.match_round !== 'Round 1') {
          if (priority !== 1) {
            return 'opponent_1';
          }
        }
      },
    },
    {
      key: 'score',
      label: 'Score',
      optionalClasses: 'block-flex flex-nowrap items-center p-4',
      component: 'TextColumn',
      checkStatus: true,
    },
  ];

  // const columns3 = [
  //   {
  //     key: 'match_no',
  //     label: 'Match #',
  //     component: 'TextColumn',
  //   },
  //   {
  //     key: 'match_round',
  //     label: 'Round',
  //     component: 'TextColumn',
  //   },
  //   {
  //     key: 'date_time',
  //     label: 'Date and Time',
  //     component: 'DateTimeColumn',
  //   },
  //   {
  //     key: 'opponent_1',
  //     label: 'Opponent 1',
  //     component: 'OpponentColumn',
  //   },
  //   {
  //     key: 'opponent_2',
  //     label: 'Opponent 2',
  //     component: 'OpponentColumn',
  //   },
  //   {
  //     key: 'score',
  //     label: 'Score',
  //     component: 'TextColumn',
  //   },
  // ];

  // console.log(tabRoundsListQueryData?.data[tabRoundsListQueryData?.data?.length - 1]);
  // console.log(typeof participantsListQueryData?.data[0].score);
  if (isFetched && data?.type?.toLowerCase() === 'team') delete columns[1];
  return (
    <div>
      <EventName sport_event={params.sportEvent} />
      {tabRoundsListQueryData?.data?.length > 0 ? (
        <>
          <TabsRounds
            tabRoundsListQueryData={tabRoundsListQueryData}
            setTabs={setTabs}
            selected={selected}
            setGroupOnTabs={setGroupOnTabs}
            enableScrollButtons
          />
          <TabsRoundsMobile
            tabRoundsListQueryData={tabRoundsListQueryData}
            setTabs={setTabs}
            selected={selected}
            setGroupOnTabs={setGroupOnTabs}
            enableScrollButtons
          />
        </>
      ) : null}
      {Group.length > 0 ? (
        <Groups Group={Group} setHeat={setHeat} selected2={selected2} pool={true} />
      ) : null}
      {selected === 'knockout' || selected === 'finals' ? (
        medalsListQueryData?.data?.length > 0 ? (
          <MedalsFinals
            medalsListQueryData={medalsListQueryData}
            isValid={medalsListQueryData?.data?.error?.length}
            Group={Group}
          />
        ) : null
      ) : null}
      <div className="justify-between items-center p-4 hidden -mb-8">
        <p className="font-bold">Match Details</p>
        <FontAwesomeIcon icon={faSearch} className="text-gray-450" />
      </div>
      <DetailItemLoader queryData={participantsListQueryData}>
        {({ data }) =>
          data && data.length > 0 ? (
            <>
              <div className="my-8 p-5 bg-gradient-to-br from-blue-75 to-purple-75 rounded-lg hidden md:block">
                <Table
                  rows={data}
                  columns={
                    selected ===
                    tabRoundsListQueryData?.data[tabRoundsListQueryData?.data?.length - 1]?.tab_id
                      ? columns2
                      : columns
                    // selected === 'matches' ? columns2 : selected === 'knockout' ? columns3 : columns
                  }
                />
              </div>

              <div className="flex flex-col gap-4 md:hidden py-2.5 px-4">
                {data.map((item, index) =>
                  item.opponent_1 ? (
                    <MobileTable key={index} Data={item} />
                  ) : (
                    <MobileCard key={index} Data={item} 
                    // showWatchVideo={true}
                     />
                  )
                )}
              </div>
            </>
          ) : (
            <div className="my-8 p-5 bg-gradient-to-br from-blue-75 to-purple-75 rounded-lg">
              <div
                className="text-center p-8 text-white font-bold rounded-lg"
                style={{
                  background: `url(/assets/sfa_online/node_modules/knockout-component/src/direflow-components/knockout-component/components/images/pattern.png) no-repeat -5% , conic-gradient(from 51.11deg at 50% 58.75%, #8E2DE2 0deg, #4A00E0 360deg)`,
                }}
              >
                Draw for this Round is not created yet
              </div>
            </div>
          )
        }
      </DetailItemLoader>
    </div>
  );
}
